<script>
import MediaSubGroup from './MediaSubGroup';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    MediaSubGroup,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    mediaentryother() {
      return this.websiteText('mediaentryother').value;
    },
    showGroupSubTitle() {
      return this.group.subGroups.length > 1 || !this.group.model;
    },
    subGroupTitle() {
      return this.showGroupSubTitle ? '' : this.group.groupSubTitle;
    },
  },
  methods: {
    getGroupName(group) {
      if (group.groupTitle == 'zzzzOther') {
        return this.mediaentryother;
      }

      return group.groupTitle;
    },
  },
};
</script>

<template>
    <div class="media-group">
        <div class="media-group_title">{{getGroupName(group)}}</div>

        <div class="media-group_sub-title" v-if="showGroupSubTitle">{{group.groupSubTitle}}</div>

        <media-sub-group v-for="subGroup in group.subGroups"
                     :key="subGroup.subGroupName"
                     :subGroup="subGroup"
                     :title="subGroupTitle">
        </media-sub-group>
    </div>
</template>

<style>
    .media-group {
        padding-bottom: 1rem;
    }

    .media-group_title {
        font-family: var(--font-header);
        font-size: 24px;
    }

    .media-group_sub-title {
        font-family: var(--font-body);
        font-size: 16px;
    }
</style>
