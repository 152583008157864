<script>
import MediaSidebar from './MediaSidebar';
import MediaContent from './MediaContent';

export default {
  components: {
    MediaSidebar,
    MediaContent,
  },
};
</script>

<template>
  <div class="media-page _layout-flex _layout-sidebarAndFilter">
    <media-sidebar />
    <media-content />
  </div>
</template>

<style>
.media-page {
  height: 100%;
  margin: 0;
  padding-top: 1rem;
}
</style>
