<script>
import { litiumBaseUrl } from '@/config';

export default {
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageFiles() {
      return this.folder.files.filter((f) => f.fileType == 'Image' || f.name.endsWith('.svg'));
    },
    canRenderAsImage() {
      return this.imageFiles.length > 0;
    },
    fileUrl() {
      return this.$toLitiumMediaUrl(this.imageFiles[0].systemId, { maxWidth: 160 });
    },
    fileTypes() {
      return this.folder.files
        .map((f) => (f.fileType != 'Other' ? f.fileType : f.extension))
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    downloadUrl() {
      return `${litiumBaseUrl}/api/media-download/entry/${this.folder.systemId}`;
    },
  },
  methods: {
    getFileTypeClass(fileType) {
      switch (fileType) {
        case 'Image':
          return 'media-entry-folder_type-icon fa fa-file-image-o';

        case 'Video':
          return 'media-entry-folder_type-icon fa fa-file-video-o';

        case 'Vector':
          return 'media-entry-folder_type-icon fa fa-file-code-o';

        default:
          return 'media-entry-folder_type-text';
      }
    },
    getFileTypeName(fileType) {
      return fileType.startsWith('.') ? fileType : '';
    },
    sendDownloadEventToGtm() {
      if (dataLayer && typeof dataLayer.push === 'function') {
        dataLayer.push({
          event: 'mediaEntryDownload',
          mediaEntry: {
            name: this.folder.name,
            url: this.downloadUrl,
          },
        });
      }
    },
  },
};
</script>

<template>
    <a class="media-entry-folder" :href="downloadUrl" @click="sendDownloadEventToGtm">
        <img class="media-entry-folder_image" v-if="canRenderAsImage" :src="fileUrl" />
        <i class="media-entry-folder_generic fa fa-file-o" v-else></i>
        <div class="media-entry-folder_types">
            <span v-for="fileType in fileTypes" :key="fileType" class="media-entry-folder_type">
                <i :class="getFileTypeClass(fileType)">{{ getFileTypeName(fileType) }}</i>
            </span>
        </div>
    </a>
</template>

<style>
.media-entry-folder {
    display: flex;
    border: 1px solid var(--color-grey500);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    width: 180px;
    height: 160px;
    cursor: pointer;
    position: relative;
}

a.media-entry-folder:hover {
    border: 1px solid var(--color-grey300);
    color: var(--color-black);
}

a.media-entry-folder:focus {
    border: 1px solid var(--color-grey300);
    color: var(--color-black);
}

.media-entry-folder_image {
    display: block;
    margin: auto;
    max-width: 140px;
    max-height: 140px;
}

.media-entry-folder_generic {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 140px;
}

.media-entry-folder_types {
    position: absolute;
    bottom: -12px;
    right: -12px;
}

.media-entry-folder_type {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 10px;
    vertical-align: middle;
    box-shadow: 0 0 0 1px var(--color-grey600);
    background-color: var(--color-grey950);
}

.media-entry-folder_type-icon {
    text-align: center;
    display: block;
    margin-top: 4px;
    margin-left: 2px;
}

.media-entry-folder_type-text {
    display: block;
    text-align: center;
}
</style>
