<script>
import MediaSidebarFilterOption from './MediaSidebarFilterOption';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    MediaSidebarFilterOption,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showMore: false,
  }),
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    filterCaption() {
      return this.filter.field || '';
    },
    filterOptions() {
      return this.filter.values || [];
    },
    filterOptionsToDisplay() {
      return this.filterOptions.length > 5 && !this.showMore
        ? this.filterOptions.slice(0, 5)
        : this.filterOptions;
    },
    canShowMore() {
      return this.filterOptions.length > 5;
    },
    mediaentryshowmore() {
      return this.websiteText('mediaentryshowmore').value;
    },
    mediaentryshowless() {
      return this.websiteText('mediaentryshowless').value;
    },
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>

<template>
  <div class="media-sidebar-filter">
    <div class="media-sidebar-filter__caption">{{filterCaption}}</div>
    <media-sidebar-filter-option v-for="(filterOption, idx) in filterOptionsToDisplay" :key="idx" :filter-option="filterOption" />
    <a href="" @click.prevent.stop="toggleShowMore" v-if="canShowMore && !showMore">{{mediaentryshowmore}}</a>
    <a href="" @click.prevent.stop="toggleShowMore" v-if="canShowMore && showMore">{{mediaentryshowless}}</a>
  </div>
</template>

<style>
    .media-sidebar-filter {
        border-bottom: 1px solid var(--color-coldGrey800);
        padding-bottom: 1rem;
    }

    .media-sidebar-filter__caption {
        text-transform: uppercase;
        font-size: 15px;
        line-height: 0.875em;
        font-family: var(--font-body);
        padding: 17px 0 17px 10px;
        color: var(--color-coldGrey220);
    }

</style>
