<script>
import MediaGroup from './MediaGroup';

export default {
  inject: ['mediabank'],
  components: {
    MediaGroup,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    groups() {
      return this.mediabank.state.groups;
    },
    currentPage() {
      return this.mediabank.state.page;
    },
    totalPages() {
      return this.mediabank.state.totalPages;
    },
  },
  methods: {
    async handleScroll() {
      if (this.loading) {
        return;
      }

      if (this.currentPage == this.totalPages) {
        return;
      }

      if (!this.shouldLoadMore()) {
        return;
      }

      this.loading = true;
      await this.loadMore();
      this.loading = false;
    },
    shouldLoadMore() {
      const currentPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight;
      return currentPosition + 200 >= document.documentElement.scrollHeight;
    },
    async loadMore() {
      await this.mediabank.loadMoreMedia(this.currentPage + 1);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<template>
  <div class="media-content">
    <media-group v-for="group in groups" :key="group.groupName" :group="group"></media-group>
  </div>
</template>

<style>
.media-content {
  width: 100%;
  height: 100%;
  margin-left: 300px;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 7px;
}
</style>
