<script>
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  inject: ['mediabank'],
  data: () => ({
    searchQueryModel: '',
    error: false,
    searchTimeout: null,
  }),
  watch: {
    searchQueryModel() {
      sessionStorage.setItem('media_searchQuery', this.searchQueryModel);
      this.search();
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    awaitingUpdates() {
      return this.mediabank.state.awaitingUpdates;
    },
    mediaentrysearchplaceholder() {
      return this.websiteText('mediaentrysearchplaceholder').value;
    },
  },
  methods: {
    searchQueryChanged(searchQuery) {
      this.searchQueryModel = searchQuery;
    },
    search() {
      this.error = false;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.performSearch();
      }, 200);
    },
    async performSearch() {
      try {
        const newUrl = location.toString().replace(location.search, '').replace('#', '');
        history.pushState(null, document.title, newUrl);

        await this.mediabank.refreshMedia();
        window.scrollTo(0, 0);
      } catch (ex) {
        console.error(ex);
        this.error = true;
      }
    },
  },
  mounted() {
    this.searchQueryModel = sessionStorage.getItem('media_searchQuery') || '';
  },
};
</script>

<template>
  <div class="media-sidebar-search">
    <div class="search-input">
      <input type="text" :placeholder="mediaentrysearchplaceholder" v-model="searchQueryModel" class="search-input__input" />
      <div class="search-input__clear-icon" v-if="searchQueryModel" @click="searchQueryModel = ''" />
      <div class="search-input__search-icon" @click="search()"/>
    </div>
  </div>
</template>

<style>

  .search-input {
    position: relative;
  }

  .search-input__input {
    width: 100%;
    padding-right: 20px;
  }

  .search-input__search-icon {
    position: absolute;
    top: 6px;
    right: 0;
    background-image: url("../../../static/icons/search_icon_black.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.2rem;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .search-input__clear-icon {
    position: absolute;
    right: 26px;
    background-image: url("../../../static/icons/close.svg");
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: 8px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    top: 6px;
  }

    .search-input__clear-icon:hover {
      color: black;
    }
</style>
