<script>
import MediaSidebarFilter from './filters/MediaSidebarFilter';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    MediaSidebarFilter,
  },
  inject: ['mediabank'],
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    requestPending() {
      return this.awaitingUpdates > 0;
    },
    filters() {
      return this.mediabank.state.filters;
    },
    awaitingUpdates() {
      return this.mediabank.state.awaitingUpdates;
    },
    mediaentryfilters() {
      return this.websiteText('mediaentryfilters').value;
    },
  },
};
</script>

<template>
  <div class="media-sidebar-filters">
    <div class="media-sidebar-filters__caption">{{ mediaentryfilters }}</div>
    <media-sidebar-filter v-for="(filter, idx) in filters" :key="idx" :filter="filter" />
  </div>
</template>

<style>
.media-sidebar-filters__caption {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0.5rem;
}
</style>
