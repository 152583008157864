<script>
import {
  MEDIA_FILTERING_QUERY_PARAMETER,
  MEDIA_FILTERING_QUERY_FILTER_GROUP_SEPARATOR,
  MEDIA_FILTERING_QUERY_FILTER_KEY_SEPARATOR,
  MEDIA_FILTERING_QUERY_FILTER_VALUES_SEPARATOR,
} from '../../../../media-constants';

export default {
  props: {
    filterOption: {
      type: Object,
      required: true,
    },
  },
  inject: ['mediabank'],
  data: () => ({
    isSelectedModel: false,
  }),
  computed: {
    requestPending() {
      return this.awaitingUpdates > 0;
    },
    hasNoResults() {
      return this.filterOption.hasNoResults;
    },
    isDisabled() {
      return this.requestPending || this.hasNoResults;
    },
    isSelected() {
      return this.filterOption.isSelected;
    },
    awaitingUpdates() {
      return this.mediabank.state.awaitingUpdates;
    },
    filters() {
      return this.mediabank.state.filters;
    },
  },
  watch: {
    isSelected() {
      this.isSelectedModel = this.isSelected;
    },
  },
  methods: {
    buildActiveFilterObj() {
      return (this.filters
        ? this.filters.filter((f) => f.values.find((v) => v.isSelected)).map((f) => ({
          [f.field.toLowerCase()]: f.values.filter((v) => v.isSelected).map((v) => v.value),
        })) : []).reduce((obj, item) => {
        Object.assign(obj, item);
        return obj;
      }, {});
    },
    setHistoryState() {
      const filterObj = this.buildActiveFilterObj();
      const filters = encodeURIComponent(Object.keys(filterObj).map((k) => `${k}${MEDIA_FILTERING_QUERY_FILTER_KEY_SEPARATOR}${filterObj[k].join(MEDIA_FILTERING_QUERY_FILTER_VALUES_SEPARATOR)}`).join(MEDIA_FILTERING_QUERY_FILTER_GROUP_SEPARATOR));

      history.pushState(null, document.title, `${document.location.href.substring(0,
        document.location.href.indexOf('?'))}${filters ? `?${MEDIA_FILTERING_QUERY_PARAMETER}=${filters}` : ''}`);
    },
    async onFilterOptionChanged() {
      this.filterOption.isSelected = this.isSelectedModel;
      this.setHistoryState();
      await this.mediabank.refreshMedia();
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.isSelectedModel = this.isSelected;
  },
};
</script>

<template>
  <label class="media-sidebar-filter-option" :class="{'selected': isSelected, 'no-results': hasNoResults}">
    <input type="checkbox" class="media-sidebar-filter-option__input" v-model="isSelectedModel" :disabled="isDisabled" @change="onFilterOptionChanged()"/>
    <span class="media-sidebar-filter-option__caption">{{filterOption.displayValue || filterOption.value}}</span>
  </label>
</template>

<style>
  .media-sidebar-filter-option {
    user-select: none;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 2em;
    padding-left: 10px;
    color: var(--color-black);
  }

  .media-sidebar-filter-option:hover {
      color: var(--color-orange700);
  }

  input[type="checkbox"].media-sidebar-filter-option__input {
    margin: 0;
  }

  input[type="checkbox"].media-sidebar-filter-option__input:disabled {
    background-color: white;
    border-color: red;
  }

  .media-sidebar-filter-option__caption {
    margin-left: 0.5rem;
  }

  .media-sidebar-filter-option.selected {
    color: var(--color-orange700);
    font-weight: bold;
  }

  .media-sidebar-filter-option.no-results {
    color: var(--color-grey450);
  }

  .media-sidebar-filter-option.disabled {
    color: var(--color-grey450);
  }
</style>
