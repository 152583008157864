<script>
import MediaFolder from './MediaFolder';

export default {
  components: {
    MediaFolder,
  },
  props: {
    subGroup: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    subGroupTitle() {
      if (this.title) {
        if (this.subGroup.subGroupTitle) {
          return `${this.title} - ${this.subGroup.subGroupTitle}`;
        }

        return this.title;
      }
      if (this.subGroup.subGroupTitle) {
        return this.subGroup.subGroupTitle;
      }

      return '';
    },
    colorClassName() {
      if (!this.subGroup.color) {
        return '';
      }

      return `media-sub-group_color media-sub-group_color-${this.subGroup.color.toLowerCase()}`;
    },
  },
};
</script>

<template>
  <div class="media-sub-group">
    <div class="media-sub-group_title" v-if="subGroupTitle">
      <span :class="colorClassName"></span>
      {{ subGroupTitle }}
    </div>

    <div class="media-sub-group_folders">
      <media-folder v-for="folder in subGroup.folders" :key="folder.name" :folder="folder"></media-folder>
    </div>
  </div>
</template>

<style>
.media-sub-group {
}

.media-sub-group_title {
  font-family: var(--font-body);
  font-size: 16px;
  padding-top: 0.5rem;
}

.media-sub-group_color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 4px;
  vertical-align: middle;
  box-shadow: 0 0 0 1px var(--color-grey600);
}

.media-sub-group_color-pink {
  background-color: var(--color-pinkCircle);
}

.media-sub-group_color-white {
  background-color: var(--color-white);
}

.media-sub-group_color-blue {
  background-color: var(--color-blueCircle);
}

.media-sub-group_color-brown {
  background-color: var(--color-brownCircle);
}

.media-sub-group_color-grey {
  background-color: var(--color-greyCircle);
}

.media-sub-group_color-green {
  background-color: var(--color-greenCircle);
}

.media-sub-group_color-yellow {
  background-color: var(--color-yellowCircle);
}

.media-sub-group_color-red {
  background-color: var(--color-redCircle);
}

.media-sub-group_color-black {
  background-color: var(--color-black);
}

.media-sub-group_color-purple {
  background-color: purple;
}

.media-sub-group_color-orange {
  background-color: orange;
}

.media-sub-group_color-beige {
  background-color: beige;
}

.media-sub-group_folders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
